var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root-container" }, [
    _c("div", { staticStyle: { "font-size": "16px" } }, [
      _c("p", [
        _c("b", [_vm._v(_vm._s(_vm.$t("auth.device-config-follow-steps")))])
      ]),
      _c("ul", [
        _c("li", [_vm._v(_vm._s(_vm.$t("auth.device-config-step-1")))]),
        _c("li", [_vm._v(_vm._s(_vm.$t("auth.device-config-step-2")))])
      ])
    ]),
    _c(
      "div",
      { staticClass: "login-container" },
      [
        _c(
          "el-form",
          {
            ref: "data",
            staticStyle: { width: "300px" },
            attrs: {
              model: _vm.data,
              rules: _vm.rules,
              "label-position": "center"
            }
          },
          [
            _c("h3", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("auth.connect-device")))
            ]),
            _c(
              "el-form-item",
              { attrs: { prop: "deviceId" } },
              [
                _c("el-input", {
                  attrs: {
                    "prefix-icon": "fa fa-user-circle",
                    type: "text",
                    "auto-complete": "on",
                    placeholder: _vm.$t("auth.enter-device-id")
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitLoginForm($event)
                    }
                  },
                  model: {
                    value: _vm.data.deviceId,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "deviceId", $$v)
                    },
                    expression: "data.deviceId"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "accessCode" } },
              [
                _c("el-input", {
                  attrs: {
                    "prefix-icon": "fa fa-key",
                    type: "text",
                    "auto-complete": "off",
                    placeholder: _vm.$t("auth.enter-access-code")
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitLoginForm($event)
                    }
                  },
                  model: {
                    value: _vm.data.accessCode,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "accessCode", $$v)
                    },
                    expression: "data.accessCode"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticStyle: { "text-align": "left" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.logining },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.submitLoginForm($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("auth.connect")))]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }