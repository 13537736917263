<template>
  <div class="root-container">
    <div style="font-size:16px">
      <p><b>{{ $t('auth.device-config-follow-steps') }}</b></p>
      <ul>
        <li>{{ $t("auth.device-config-step-1") }}</li>
        <li>{{ $t("auth.device-config-step-2") }}</li>
      </ul>
      
    </div>
    <div class="login-container">

      <el-form :model="data" :rules="rules" ref="data" label-position="center" style="width: 300px">
        <h3 class="title">{{ $t('auth.connect-device') }}</h3>

        <el-form-item prop="deviceId">
          <el-input prefix-icon="fa fa-user-circle" type="text" v-model="data.deviceId" auto-complete="on" :placeholder="$t('auth.enter-device-id')" @keyup.enter.native="submitLoginForm"></el-input>
        </el-form-item>

        <el-form-item prop="accessCode">
          <el-input prefix-icon="fa fa-key" type="text" v-model="data.accessCode" auto-complete="off" :placeholder="$t('auth.enter-access-code')" @keyup.enter.native="submitLoginForm"></el-input>
        </el-form-item>

        <el-form-item style="text-align: left">
          <el-button type="primary" @click.native.prevent="submitLoginForm" :loading="logining">{{ $t('auth.connect') }}</el-button>

        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AccessCode",
    data: function () {
      return {
        logining: false,
        data: {
          deviceId: "",
          accessCode: ""
        },
        rules: {
          deviceId: [
            { required: true, message: this.$t('auth.form-rules.enter-device-id'), trigger: 'blur' }
          ],
          accessCode: [
            { required: true, message: this.$t('auth.form-rules.enter-access-code'), trigger: 'blur' }
          ]
        }
      }
    },

    methods: {
      submitLoginForm: function (event) {
        const self = this

        this.$refs.data.validate((valid) => {
          if (valid) {
            this.logining = true;

            this.$store.dispatch('connectToDevice', this.data).then(response => {
              console.log(response)
              this.logining = false;

              this.$router.push({name: 'Dashboard'})

            }).catch(error => {
              console.log(error)
              this.logining = false;
            })
          }
        })
      },
    },

    
  }
</script>


<style scoped>
  .root-container {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    height: 100%; 
  }
  .login-container {
    /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    /*margin: 80px auto;*/
    margin: 20px;
    width: 500px;
    padding: 35px 35px 15px 35px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
  }
</style>